/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { VWLink } from './VWLink';
import { container } from '../composableStyles/layout';
import { SocialLinks } from './SocialLinks';
import { ContactLinks } from './ContactLinks';
import { DARK_COMPONENT_THEME, EXTERNAL_LINK_TYPE } from '../utilities/constants';
import { fluidScale } from '../composableStyles/fluidScale';
import { defaultLinkStyles } from '../composableStyles/defaultLinkStyles';
import { widestScaleFactor } from '../composableStyles/text';
import {
  MOBILE_BP, WIDEST_BP, WIDE_BP,
} from '../composableStyles/theme';
import { FooterLogo } from './FooterLogo';
import { FooterLegal } from './FooterLegal';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';
import { FooterAcknowledgement } from './FooterAcknowledgement';

const headerText = theme => css`
  color: ${theme.colors.pink};
  font-family: ${theme.font.family.alternate};
  font-weight: ${theme.font.weight.extraBold};
  letter-spacing: 0;
  line-height: ${24 / 14};
  margin-bottom: ${theme.spacer(0.5)};
  ${fluidScale({
    minPx: 14, maxPx: 14 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

const navText = theme => css`
  color: ${theme.colors.white};
  letter-spacing: ${0.2 / 13}em;
  line-height: ${22 / 13};
  ${fluidScale({
    minPx: 13, maxPx: 13 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

const navLink = theme => css`
  ${defaultLinkStyles(theme, true)}
`;

const navListItem = theme => css`
  margin-bottom: ${theme.spacer(0.4)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledFooter = styled.footer`
  --vw-logo-color: ${props => props.theme.colors.black};
  --vw-logo-background: ${props => props.theme.colors.white};

  background-color: ${props => props.theme.colors.black};
  padding-top: ${props => props.theme.spacer(4.5)};
  padding-bottom: ${props => props.theme.spacer(3)};
  margin-top: auto;

  ${props => props.theme.bp.tablet} {
    padding-top: ${props => props.theme.spacer(7)};
  }
`;

const StyledAddressHeading = styled.h3`
  color: ${props => props.theme.colors.pink};
  font-family: ${props => props.theme.font.family.alternate};
  font-weight: ${props => props.theme.font.weight.extraBold};
  letter-spacing: 0;
  line-height: ${24 / 14};
  margin-bottom: ${props => props.theme.spacer(0.5)};
  ${fluidScale({
    minPx: 14, maxPx: 14 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.font.family.alternate};
  font-weight: ${props => props.theme.font.weight.extraBold};
  letter-spacing: 0;
  line-height: ${32 / 26}em;
  margin-bottom: ${48 / 26}em;
  ${fluidScale({
    minPx: 26, maxPx: 48, minMqPx: MOBILE_BP, maxMqPx: 900,
  })}

  ${props => props.theme.bp.min(900)} {
    line-height: ${36 / 28};
    letter-spacing: ${-0.5 / 28}em;
    ${fluidScale({
    minPx: 28, maxPx: 28 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }

  ${props => props.theme.bp.min(900)} {
    margin-bottom: 0;
  }
`;

const StyledAddressLi = styled.li`
  margin-bottom: ${props => props.theme.spacer(0.4)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledAddressLink = styled(VWLink)`
  ${props => navText(props.theme)}
  ${props => navLink(props.theme)}
`;

export const Footer = () => {
  const theme = useTheme();
  const queryData = useStaticQuery(graphql`
    {
      prismicContactDetails {
        _previewable
        data {
          address_line_1
          address_line_2
          address_line_3
          address_line_4
          directions_link
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(queryData);

  const {
    address_line_1: address1,
    address_line_2: address2,
    address_line_3: address3,
    address_line_4: address4,
    directions_link: directionsLink,
  } = data.prismicContactDetails.data;

  const mq500 = theme.bp.min(500);
  const mqLayoutChange = theme.bp.min(900);

  return (
    <StyledFooter
      {...addNavColorAttribute(DARK_COMPONENT_THEME)}
    >
      <div
        css={[
          container(theme, 22),
          css`
            ${mqLayoutChange} {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
            }
          `,
        ]}
      >
        <StyledHeading>
          <span css={css`display: block`}>Let’s build something</span>
          <span>amazing together.</span>
        </StyledHeading>
        <div
          css={css`
            flex: 0 1 56%;

            ${theme.bp.wide} {
              flex-basis: ${(theme.layout.maxWidthColsPx(14) / theme.layout.maxWidthColsPx(24)) * 100}%;
            }
          `}
        >
          <address
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: ${theme.spacer(2.25)};
              flex-wrap: wrap;

              ${theme.bp.tablet} {
                flex-wrap: nowrap;
                margin-bottom: ${theme.pxToRem(101)}rem;
              }
            `}
          >
            <ContactLinks
              StyledSection={styled.section``}
              StyledHeading={StyledAddressHeading}
              StyledLi={StyledAddressLi}
              StyledLink={StyledAddressLink}
            />
            <SocialLinks
              StyledSection={styled.section`
                flex: 0 0 30%;

                ${mq500} {
                  flex: 0 0 auto;
                }
              `}
              StyledHeading={StyledAddressHeading}
              StyledLi={StyledAddressLi}
              StyledLink={StyledAddressLink}
            />
            <section
              css={css`
                flex: 0 0 100%;

                ${mq500} {
                  flex: 0 0 auto;
                }
              `}
            >
              <h2 css={headerText(theme)}>VenturePlex</h2>
              <span
                css={[
                  navListItem(theme),
                  navText(theme),
                  css`
                    display: block;
                    color: ${theme.colors.greyMurray};
                  `,
                ]}
              >
                {address1}
                <br />
                {address2}
                <br />
                {address3}
                <br />
                {address4}
              </span>
              <VWLink
                css={[navText(theme), navLink(theme)]}
                url={directionsLink}
                label="Get Directions"
                type={EXTERNAL_LINK_TYPE}
              />
            </section>
          </address>
        </div>
      </div>
      <div
        css={[
          container(theme, 22),
          css`
            ${theme.bp.tablet} {
              display: flex;
              align-items: center;
            }
          `,
        ]}
      >
        <FooterLogo />
        <div
          css={css`
            ${theme.bp.tablet} {
              margin-left: auto;
            }
          `}
        >
          <FooterLegal />
          <FooterAcknowledgement />
        </div>
      </div>
    </StyledFooter>
  );
};
